'use client'

import { useResources } from '@/context/ResourcesContext'
import ZbBadge from '@/components/Badge'
import ZbImage from '@/components/ZbImage'
import styles from './hero.module.css'
import ZbLink from '@/components/ZbLink'
import clsx from 'clsx'
import LocationInput from '@/components/LocationInput'
import { HtmlWithOptionalScrollbar } from '@/components/HtmlWithOptionalScrollbar/HtmlWithOptionalScrollbar'

type ZbHeroProps = {
  backgroundImage: string
  badgeTitle: string
  badgeSubtitle: string
  title: string
  subtitle: string
  tickersList: { field_stars: number[]; field_text: string }[]
  className: string
}

export default function ZbHero({
  backgroundImage,
  badgeTitle,
  badgeSubtitle,
  title,
  subtitle,
  tickersList,
  className,
}: ZbHeroProps) {
  const resources = useResources()

  return (
    <section className={clsx(styles.hero, className)}>
      <ZbImage
        className={styles.backgroundImage}
        fill
        loading="lazy"
        src={backgroundImage}
      />
      <div className={styles.backgroundOverlay} />
      {badgeTitle && badgeSubtitle && (
        <div className={styles.badgeWrapper}>
          <ZbBadge title={badgeTitle} subtitle={badgeSubtitle} />
        </div>
      )}
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <HtmlWithOptionalScrollbar
          html={subtitle}
          className={styles.subtitle}
        />

        <div className={styles.inputContainer}>
          <LocationInput />
        </div>

        {resources && resources.locations_viewAllLocationsUrl && (
          <ZbLink
            to={resources.locations_viewAllLocationsUrl}
            className={styles.link}
          >
            <span>{resources.locations_viewAllLocationsText}</span>
          </ZbLink>
        )}
      </div>

      {tickersList && tickersList.length > 0 && (
        <div className={styles.tickersWrapper}>
          <ul className={styles.tickers}>
            {tickersList?.map((ticker) => (
              <li key={ticker.field_text}>
                <span className={styles.text}>{ticker.field_text}</span>
              </li>
            ))}
            {tickersList?.map((ticker) => (
              <li key={`duplicate-${ticker.field_text}`}>
                <span className={styles.text}>{ticker.field_text}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  )
}
