import cardStyles from '@/components/Card/card.module.css'
import styles from './quickstartcard.module.css'
import { ZbLinkProps } from '@/components/ZbLink'
import ZbImage from '@/components/ZbImage'
import { Flex } from '@radix-ui/themes'
import DOMPurify from 'isomorphic-dompurify'
import clsx from 'clsx'
import ZbCheckButton from '@/components/buttons/CheckButton'
import { useResources } from '@/context/ResourcesContext'
import { useRef } from 'react'
import { useQuickstartContext } from '@/components/VolumeCalculatorQuickstart/quickstartContext'
import { wrapTables } from '@/hooks/useSanitizedHtml'

type CardProps = {
  siteCode?: string
  sizeName?: string
  image?: {
    url: string
    size?: { w: number; h: number }
    subtitle?: string
    alt: string
  }
  link?: {
    text: string
    url: string
    type?: string
    linkInNewTab?: boolean
  }
  cta?: {
    text: string
    url: string
    type: 'unstyled' | 'button' | 'base' | 'arrow'
    linkInNewTab?: boolean
  }
  noSelection?: boolean
  redirect?: boolean
  imgZoom?: boolean
  title: { text: string; size: string }
  text?: string
  gap?: string
  withButton?: boolean
  withShadow?: boolean
  ctaBtnSize?: ZbLinkProps['size']
  onPageTitleElement?: boolean
  alignStart?: boolean
  cardType?: 'default' | 'tabs' | 'carousel'
  onTeaserTextImage?: boolean
  className?: string | string[]
  step?: number
}

export default function QuickstartCard({
  siteCode = '0',
  sizeName = null,
  image = { url: '', size: { w: 320, h: 250 }, subtitle: '', alt: '' },
  imgZoom = false,
  title = { text: '', size: 'h4' },
  text = '',
  gap = '10px',
  withShadow = true,
  alignStart = false,
  cardType = 'default',
  className = '',
  noSelection = true,
  step = 1,
}: CardProps) {
  const sanitizedText = DOMPurify.sanitize(wrapTables(text) || '')
  const resources = useResources()
  const { setLocation, setSize, quickstartSelections } = useQuickstartContext()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const isSelected = () => {
    if (step === 1) {
      return siteCode === quickstartSelections.location
    } else if (step === 3) {
      return sizeName === quickstartSelections.size
    }
    return false
  }

  const classNames = Array.isArray(className)
    ? clsx(...className.map((cn) => styles[cn] || cn))
    : className

  const handleCardClick = () => {
    if (step === 1) {
      setLocation(siteCode)
      if (quickstartSelections.location === siteCode) {
        setLocation(null)
      }
    } else {
      setSize(sizeName)
      if (quickstartSelections.size === sizeName) {
        setSize(null)
      }
    }
  }

  return (
    <Flex
      className={clsx(classNames, {
        [cardStyles.cardShadow]:
          (withShadow && isSelected()) || (withShadow && noSelection),
      })}
      direction="column"
      align={alignStart ? 'start' : 'stretch'}
      gap="10px"
      onClick={handleCardClick}
    >
      {image.url !== '' ? (
        <div
          className={clsx(
            cardStyles.cardImage,
            styles.cardImage,
            cardType !== 'default' ? cardStyles[cardType + 'CardImage'] : '',
            styles[className + 'Image'],
            {
              [cardStyles.zoomableImg]: imgZoom,
            }
          )}
        >
          <ZbImage
            alt={image.alt}
            height={image.size?.h}
            src={image.url}
            fill={true}
            loading="lazy"
            width={image.size?.w}
            zoomImage={imgZoom}
          />
        </div>
      ) : (
        <div
          className={clsx(
            cardStyles.cardImage,
            styles.cardImage,
            cardType !== 'default' ? cardStyles[cardType + 'CardImage'] : '',
            {
              [cardStyles.zoomableImg]: imgZoom,
            }
          )}
        ></div>
      )}
      <Flex
        direction="column"
        gap={gap}
        width="100%"
        align={'center'}
        className={clsx(styles[className + 'Box'], {
          [cardStyles.alignStart]: alignStart,
        })}
      >
        <div
          className={clsx(
            cardStyles.cardTitleContainer,
            cardType !== 'default' ? cardStyles[cardType + 'CardTitle'] : ''
          )}
        >
          <h4 className={`${cardStyles.cardTitle}`}>{title.text}</h4>
        </div>
        <div
          className={clsx(
            styles.cardText,
            cardType !== 'default' ? cardStyles[cardType + 'CardText'] : ''
          )}
          dangerouslySetInnerHTML={{ __html: sanitizedText }}
        />
        <ZbCheckButton
          ref={buttonRef}
          withHover={false}
          isSelected={isSelected()}
          className={styles.checkButton}
          text={resources.general_select}
        />
      </Flex>
    </Flex>
  )
}
