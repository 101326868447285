'use client'

import { useEffect, useRef, useState } from 'react'
import { useResources } from '@/context/ResourcesContext'
import { usePathname } from '@/i18n/routing'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import * as Tabs from '@radix-ui/react-tabs'
import clsx from 'clsx'
import styles from './tabs.module.css'
import Card from '@/components/Card'
import ZbSlider from '@/components/ZbSlider'
import { Map } from '@/components/Map'
import { drupalBaseUrl } from '@/lib/config'

interface LocationTeaserType {
  title: string
  latitude: number
  longitude: number
  sitecode: number
  content: string
  cta: {
    url: string
    title: string
  }
  is_teaser: boolean
  overlay_text: string
  image: {
    url: string
    alt: string
  }
  field_title: string
  field_link: string
}

function slugify(s) {
  return s.toLowerCase().replace(/\s+/g, '-')
}

export default function ZbTabs({
  data,
  className,
  tabsType,
  tabContent,
  onValueChange,
  disableScroll = false,
}: {
  data: LocationTeaserType[] | any[]
  className?: string
  tabsType?: string
  tabContent?: React.ReactNode[]
  onValueChange?: (value: string) => void
  disableScroll?: boolean
}) {
  const pathname = usePathname()
  const { locations_cards, locations_map, ...resources } = useResources()
  const activeElementRef = useRef<HTMLButtonElement | null>(null)
  const tabListRef = useRef<HTMLDivElement | null>(null)

  const getDefaultTab = () => {
    if (tabsType === 'locationTeaser') {
      return locations_cards ? slugify(locations_cards) : ''
    }

    if (data && data.length > 0 && data[0].field_link) {
      return slugify(data[0].field_link)
    }

    return ''
  }

  const [activeTab, setActiveTab] = useState(getDefaultTab())

  useEffect(() => {
    const hash = window.location.hash.substring(1)
    if (hash) {
      if (tabsType === 'locationTeaser') {
        if (
          hash === slugify(locations_cards) ||
          hash === slugify(locations_map)
        ) {
          setActiveTab(hash)
        }
      } else {
        const validTab = data.some((item) => slugify(item.field_link) === hash)
        if (validTab) {
          setActiveTab(hash)
        }
      }
    }
  }, [data, locations_cards, locations_map, tabsType])

  const handleTabChange = (tabValue: string) => {
    const formattedTabValue = slugify(tabValue)
    setActiveTab(formattedTabValue)
    if (onValueChange) {
      onValueChange(formattedTabValue)
    }
    if (!disableScroll) {
      if (activeElementRef.current) {
        activeElementRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }
      if (tabListRef?.current) {
        tabListRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }
    }
    window.location.hash = formattedTabValue
  }

  useEffect(() => {
    const hash = window.location.hash.substring(1)

    if (!disableScroll) {
      if (activeElementRef?.current) {
        activeElementRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
      }

      if (tabListRef?.current && hash !== '') {
        tabListRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }
    }
  }, [pathname, disableScroll, activeTab])

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1)
      
      if (!hash) return;
      
      if (tabsType === 'locationTeaser') {
        if (hash === slugify(locations_cards)) {
          setActiveTab(slugify(locations_cards))
          return;
        } 
        
        if (hash === slugify(locations_map)) {
          setActiveTab(slugify(locations_map))
          return;
        }
        
        if (activeTab !== slugify(locations_cards) && hash.includes(slugify(locations_cards))) {
          setActiveTab(slugify(locations_cards))
        } else if (activeTab !== slugify(locations_map) && hash.includes(slugify(locations_map))) {
          setActiveTab(slugify(locations_map))
        }
      } else {
        const exactMatch = data.find(item => hash === slugify(item.field_link));
        if (exactMatch) {
          setActiveTab(slugify(exactMatch.field_link));
          return;
        }
        
        const partialMatch = data.find(item => 
          activeTab !== slugify(item.field_link) && hash.includes(slugify(item.field_link))
        );
        
        if (partialMatch) {
          setActiveTab(slugify(partialMatch.field_link));
        }
      }
    }

    window.addEventListener('hashchange', handleHashChange)
    window.addEventListener('focus', handleHashChange) // Check hash when window gets focus
    
    handleHashChange()
    
    let lastHash = window.location.hash
    let rafId: number | null = null
    
    const checkHash = () => {
      const currentHash = window.location.hash
      if (currentHash !== lastHash) {
        lastHash = currentHash
        handleHashChange()
      }
      rafId = requestAnimationFrame(checkHash)
    }
    
    rafId = requestAnimationFrame(checkHash)
    
    return () => {
      window.removeEventListener('hashchange', handleHashChange)
      window.removeEventListener('focus', handleHashChange)
      if (rafId !== null) {
        cancelAnimationFrame(rafId)
      }
    }
  }, [data, locations_cards, locations_map, tabsType, activeTab])

  return (
    <section className={clsx(className, styles.tabsSection)}>
      <Tabs.Root
        className={styles.tabsContainer}
        value={activeTab}
        defaultValue={activeTab}
        onValueChange={handleTabChange}
      >
        {tabsType === 'locationTeaser' ? (
          <>
            <Tabs.List ref={tabListRef} className={clsx(styles.tabsList)}>
              <Tabs.Trigger
                className={clsx(
                  activeTab === slugify(locations_cards)
                    ? styles.tabsActive
                    : ''
                )}
                value={slugify(locations_cards)}
                onClick={() => handleTabChange(locations_cards)}
              >
                {locations_cards}
              </Tabs.Trigger>
              <Tabs.Trigger
                className={clsx(
                  activeTab === slugify(locations_map) ? styles.tabsActive : ''
                )}
                value={slugify(locations_map)}
                onClick={() => handleTabChange(locations_map)}
              >
                {locations_map}
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value={slugify(locations_cards)}>
              <div className={clsx(styles.tabsCardsCarousel, 'hideSmAbove')}>
                <ZbSlider centerModeMobile={true} centerPaddingMobile="25px">
                  {data.map((item, index) => (
                    <Card
                      key={index}
                      title={{ text: item.title, size: 'h3' }}
                      imgZoom={true}
                      text={item.content}
                      cardType="carousel"
                      link={{
                        text: item.cta.title,
                        url: item.cta.url,
                        type: 'base',
                      }}
                      image={{
                        url: `${drupalBaseUrl}${item.image.url}`,
                        subtitle: item.image.alt,
                        alt: item.image.alt,
                      }}
                      cta={{
                        text: resources.volumecalc_bookNow,
                        url: `${resources.volumecalc_url.replace(/\/[^\/]+\//, '/')}/?location=${item.sitecode}`,
                        type: 'button',
                      }}
                    />
                  ))}
                </ZbSlider>
              </div>
              <div className={clsx(styles.cardsContainer, 'smDisplayNone')}>
                {data.map((item, index) => (
                  <Card
                    key={index}
                    title={{ text: item.title, size: 'h3' }}
                    imgZoom={true}
                    text={item.content}
                    cardType="tabs"
                    link={{
                      text: item.cta.title,
                      url: item.cta.url,
                      type: 'base',
                    }}
                    image={{
                      url: `${drupalBaseUrl}${item.image.url}`,
                      subtitle: item.image.alt,
                      alt: item.image.alt,
                    }}
                    cta={{
                      text: resources.volumecalc_bookNow,
                      url: `${resources.volumecalc_url.replace(/\/[^\/]+\//, '/')}/?location=${item.sitecode}`,
                      type: 'button',
                    }}
                  />
                ))}
              </div>
            </Tabs.Content>
            <Tabs.Content value={slugify(locations_map)}>
              <Map data={data} />
            </Tabs.Content>
          </>
        ) : (
          <>
            <Tabs.List ref={tabListRef} className={styles.tabsList}>
              <ScrollArea.Root className={styles.TabsScrollarea}>
                <ScrollArea.Viewport className={styles.TabsScrollViewport}>
                  {data.map((item) => (
                    <Tabs.Trigger
                      key={item.field_link}
                      className={clsx(
                        activeTab === slugify(item.field_link)
                          ? styles.tabsActive
                          : ''
                      )}
                      value={slugify(item.field_link)}
                      onClick={() => handleTabChange(item.field_link)}
                      ref={
                        activeTab === slugify(item.field_link)
                          ? activeElementRef
                          : null
                      }
                    >
                      {item.field_title}
                    </Tabs.Trigger>
                  ))}
                  <ScrollArea.Scrollbar orientation="horizontal">
                    <ScrollArea.Thumb data-state="hidden" />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Viewport>
              </ScrollArea.Root>
            </Tabs.List>
            {tabContent?.map((content, index) => (
              <Tabs.Content key={index} value={slugify(data[index].field_link)}>
                {content}
              </Tabs.Content>
            ))}
          </>
        )}
      </Tabs.Root>
    </section>
  )
}
