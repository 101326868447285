// @ts-nocheck

import {
  DrupalMenuLinkContent,
  DrupalTranslatedPath,
  JsonApiResource,
} from 'next-drupal'
import { DrupalJsonApiParams } from 'drupal-jsonapi-params'
import { drupal } from '@/lib/drupal'
import { routing } from '@/i18n/routing'
import { ResourceTypeIncludes } from '@/lib/drupal-entities'
import { drupalBaseUrl } from '@/lib/config'
import { countryCode } from '@/lib/config'
import i18nConfig from '@/i18n/i18n.config'

export async function getStorageLocations(locale: string = 'de') {
  const country = countryCode || process.env.NEXT_PUBLIC_COUNTRY_CODE
  const baseUrl =
    i18nConfig[country]?.url !== ''
      ? i18nConfig[country].url
      : `https://www.zebrabox.${country}`

  try {
    const response = await fetch(`${baseUrl}/api/${locale}/storagelocations`, {
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return response.json()
  } catch (error) {
    console.error('Error fetching storage locations:', error)
    throw error
  }
}

async function getArticles(locale: string) {
  const params = new DrupalJsonApiParams()
    .addInclude(['field_preview_image'])
    .addInclude(['field_article_content'])
    .addFilter('created', '2023%', '>=')
    .addSort('created', 'DESC')
  const articles = await drupal.getResourceCollection('node--article', {
    params: params.getQueryObject(),
  })

  return articles.map((article) => {
    return {
      drupalId: article.id,
      title: article.title,
      date: article.created,
      image: getImage(article?.field_preview_image?.id, locale) || {},
      link: {
        url: article.drupal_internal__nid_link,
      },
      text: article?.field_preview_lead?.value || '',
    }
  })
}

/**
 * Fetches all articles from Drupal with preview image and content
 * Filters for articles with featured flag and sorts by creation date descending
 *
 * @param locale - The current locale/language code
 * @param featured - Whether to fetch featured articles
 * @param limit - The number of articles to fetch
 * @returns Array of formatted article objects containing:
 *  - drupalId: Unique Drupal ID
 *  - title: Article title
 *  - date: Creation date
 *  - image: Preview image object
 *  - link: Article URL
 *  - text: Preview text/lead
 */
export async function getFeaturedArticles(locale: string, featured?: boolean, limit: number = 2) {
  const params = {
    'fields[node--article]': [
      'drupal_internal__nid',
      'title',
      'created',
      'field_preview_lead',
      'field_preview_image',
      'path',
      'path.alias',
      'field_category',
    ].join(','),
    sort: '-created',
    'page[limit]': limit,
    include: 'field_preview_image.field_media_image,field_category',
    ...(featured && { 'filter[field_featured]': '1' })
  }

  const articles = await drupal.getResourceCollection('node--article', {
    params,
    locale,
    defaultLocale: routing.defaultLocale,
  })

  return {
    articles: articles.map(
      ({
        drupal_internal__nid,
        title,
        created,
        field_preview_image,
        field_preview_lead,
        path,
        field_category
      }) => ({
        drupalId: drupal_internal__nid,
        title,
        date: created,
        image: field_preview_image?.field_media_image?.uri?.url,
        text: field_preview_lead?.processed,
        url: path?.alias,
        category: {
          name: field_category?.name,
          url: field_category?.path?.alias
        }
      })
    ),
  }
}

export async function getImage(imgUuid: string, locale: string) {
  const params = new DrupalJsonApiParams().addInclude(['field_media_image'])

  if (imgUuid === '') {
    return {
      url: '',
      alt: '',
    }
  }

  return drupal
    .getResource('media--image', imgUuid, {
      params,
      locale,
      defaultLocale: routing.defaultLocale,
    })
    .then((res) => {
      return {
        url: drupalBaseUrl + res.field_media_image.uri.url,
        alt: res.field_media_image.resourceIdObjMeta.alt,
      }
    })
}

type ZbNavigationChildren = {
  children?: ZbNavigationChildren[]
  description: string
  drupalId: string
  imageID?: string
  link: { url: string; text: string }
  relationships?: any
  text: string
  title: string
}

type ZbNavigation = {
  children: ZbNavigationChildren[]
  drupalId: string
  text: { text: string; url: string } | string
  title: { text: string; url: string }
  url: { url: string; text: string }
}

// TODO: Add types for the DrupalMenuLinkContent
export type CustomDrupalMenuLinkContent = DrupalMenuLinkContent & {
  attributes: any
  relationships: any
}

/**
 * Helper function to parse navigation items from Drupal menu link content.
 *
 * @param items - Array of menu link content items from Drupal
 * @returns Array of parsed navigation items with title, url and text properties
 */
function parseNavigationItems(items: any[] = []) {
  return items.map(({ title, url, field_subnav_text, link_override }) => ({
    title,
    url: link_override?.uri ? link_override.uri.replace('internal:', '').replace(/^\/[a-z]{2}\//, '/') : url,
    text: field_subnav_text,
  }))
}

/**
 * Helper function to parse the top section of a navigation item.
 * Extracts and formats the title, URL, image, text and link text.
 *
 * @param item - Navigation item object from Drupal containing:
 *   - title: Section title
 *   - url: Section URL
 *   - field_subnav_image: Image object with media image URI
 *   - field_subnav_text: Section description text
 *   - field_subnav_link_text: Text for the section link
 * @returns Object containing formatted title, url, image URL, text and link text
 */
function parseTopSection(item: any) {
  if (!item) return {}

  const {
    title = '',
    url = '',
    field_subnav_image,
    field_subnav_text,
    field_subnav_link_text,
  } = item

  return {
    title,
    url,
    image: { url: field_subnav_image?.field_media_image?.uri?.url },
    text: field_subnav_text,
    link_text: field_subnav_link_text,
  }
}

/**
 * Helper function to parse the right column section of navigation.
 * Handles different paragraph types and formats their data accordingly.
 *
 * @param subnav - Navigation subnav object containing type-specific fields
 * @param items - Array of navigation items
 * @param featuredArticles - Array of featured article objects
 * @returns Object containing formatted data based on paragraph type:
 *   - For services: type, title and children with title/url/text
 *   - For blog: type, categories, links, titles
 *   - For locations: type, title and children with title/url
 *   - For blog preview: type, title and children (featured articles)
 */
function parseRightColumn(subnav: any, items: any[], featuredArticles: any) {
  if (!subnav) return {}

  const mapTitleUrl = ({ title, url }: any) => ({
    title,
    url: url,
  })

  const mapTitleUrlText = ({ title, url, field_subnav_text }: any) => ({
    title,
    url,
    text: field_subnav_text || '',
  })

  const handlers = {
    'paragraph--subnav_services': {
      type: 'services',
      title: subnav.field_title_right,
      children: items.slice(2).map(mapTitleUrlText),
    },
    'paragraph--subnav_blog': {
      type: 'blog',
      categories: (subnav.field_categories || []).map(({ name }) => ({
        title: name,
        url: `/blog/${slugify(name)}`,
      })),
      links: (subnav.field_links || []).map(mapTitleUrl),
      title: subnav.field_title_categories,
      title_bottom: subnav.field_title_right,
    },
    'paragraph--subnav_locations': {
      type: 'locations',
      title: subnav.field_title_right,
      children: (subnav.field_links || []).map(mapTitleUrl),
    },
    'paragraph--subnav_blog_preview': {
      type: 'blog_preview',
      title: subnav.field_title_right,
      children: featuredArticles,
    },
  }

  return handlers[subnav.type as keyof typeof handlers] || {}
}

export async function alternateNav(navDataTree) {
  return navDataTree.map((item) => {
    const drupalID = item?.meta?.entity_id

    const altNavChildren = item.items.map((subItem) => {
      const children = subItem?.items?.map((subItemChildren) => {
        return {
          id: subItemChildren?.meta?.entity_id,
          title: subItemChildren?.title,
          url: subItemChildren?.link_override?.uri ? subItemChildren?.link_override?.uri?.replace('internal:', '').replace(/^\/[a-z]{2}\//, '/') : subItemChildren?.url,
        }
      })

      return {
        id: subItem?.meta?.entity_id,
        title: subItem?.title,
        url: subItem?.url,
        children: children,
      }
    })

    return {
      id: drupalID,
      title: item?.title,
      url: item?.url,
      children: altNavChildren,
    }
  })
}

export async function navigationParser(
  navDataTree: CustomDrupalMenuLinkContent[],
  locale: string
): Promise<ZbNavigation[]> {
  const featuredArticles = await getFeaturedArticles(locale, false, 2)

  return Promise.all(
    navDataTree.map(async (item) => {
      const { field_subnav: subnav, title, url, items = [], meta } = item
      const [firstItem, secondItem] = items

      const getTopSection = (
        subnavType?: string,
        subnavTitle?: string,
        firstItemTitle?: string
      ) => ({
        title:
          subnavType === 'paragraph--subnav_locations'
            ? subnavTitle
            : [
                  'paragraph--subnav_blog_preview',
                  'paragraph--subnav_services',
                ].includes(subnavType || '')
              ? firstItemTitle
              : title,
      })

      const getBottomChildren = () => {
        if (!subnav) return parseNavigationItems(firstItem?.items || item.items)

        switch (subnav.type) {
          case 'paragraph--subnav_locations':
            return parseNavigationItems(subnav.field_locations)
          case 'paragraph--subnav_blog':
            return featuredArticles
          case 'paragraph--subnav_services':
            return []
          default:
            return parseNavigationItems(firstItem?.items || item.items)
        }
      }

      const result = {
        drupalId: meta.entity_id,
        leftCol: {
          title: { text: title, url },
          text: item?.field_subnav_text,
          url: { text: item?.field_subnav_link_text, url },
        },
        middleCol: {
          leftCol: {
            top: !subnav
              ? {}
              : {
                  ...getTopSection(
                    subnav.type,
                    subnav.field_title_left,
                    firstItem?.title
                  ),
                  url: firstItem?.url || '',
                  image:
                    firstItem?.field_subnav_image?.field_media_image?.uri?.url,
                  text: firstItem?.field_subnav_text,
                  link_text:
                    subnav.type === 'paragraph--subnav_blog'
                      ? subnav.field_cta?.title
                      : firstItem?.field_subnav_link_text,
                },
            bottom: {
              children: getBottomChildren(),
            },
          },
          rightCol:
            subnav &&
            !['paragraph--subnav_blog', 'paragraph--subnav_locations'].includes(
              subnav.type
            )
              ? {
                  top: parseTopSection(secondItem),
                  children: parseNavigationItems(secondItem?.items),
                }
              : {},
        },
        rightCol: parseRightColumn(subnav, item.items, featuredArticles),
      }

      return result
    })
  )
}

export async function getAppSettings() {
  try {
    const response = await drupal.fetch(
      drupal.buildUrl('/app/settings').toString()
    )

    if (!response.ok) {
      throw new Error('Failed to fetch app settings')
    }

    const contentType = response.headers.get('content-type')
    if (!contentType || !contentType.includes('application/json')) {
      throw new Error('Received non-JSON response')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching app settings:', error)
    return null
  }
}

export async function initResources(locale: string) {
  const url = drupal.buildUrl('/translations.php').toString()
  const resp = await drupal.fetch(url)
  const resources = await resp.json()

  return resources[locale]
}

export const slugify = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/&/g, 'und')
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

export async function getDrupalResources(
  locale,
  path
): Promise<{ resource: JsonApiResource; pathLookup: DrupalTranslatedPath }> {
  try {
    const pathLookup = await fetch(
      `${drupalBaseUrl}/router/translate-path?path=${path}`
    ).then((res) => {
      return res.json()
    })

    if (pathLookup?.entity?.uuid) {
      let include = ''

      switch (pathLookup.entity.bundle) {
        case 'article':
          include = ResourceTypeIncludes.NODE__ARTICLE
          break
        case 'page':
          include = ResourceTypeIncludes.NODE__PAGE
          break
      }

      const resource = await drupal.getResource(
        pathLookup.jsonapi.resourceName,
        pathLookup.entity.uuid,
        {
          params: {
            'filter[langcode]': locale,
            'filter[status]': 1,
            include: include,
          },
          locale,
        }
      )

      return { resource, pathLookup }
    }
  } catch (error) {
    console.error('Error fetching from Drupal:', error)
  }
}

/**
 * Fetches all taxonomy terms of type 'tags' from Drupal
 *
 * @param locale - The current locale/language code
 * @returns Array of tag objects containing:
 *  - id: Unique Drupal ID
 *  - name: Tag name
 *  - path: URL path/alias for the tag
 */
export async function getTags(locale: string) {
  const params = {
    'fields[taxonomy_term--tags]': [
      'drupal_internal__tid',
      'name',
      'path',
      'path.alias',
    ].join(','),
    'filter[langcode]': locale,
    'sort': 'name',
  }

  try {
    const tags = await drupal.getResourceCollection('taxonomy_term--tags', {
      params,
      locale,
      defaultLocale: routing.defaultLocale,
    })

    return {
      tags: tags.map(({ drupal_internal__tid, name, path }) => ({
        id: drupal_internal__tid,
        name,
        url: path?.alias
      }))
    }
  } catch (error) {
    console.error('Error fetching taxonomy terms:', error)
    throw error
  }
}
