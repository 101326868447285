import {
  AdvancedMarker,
  useAdvancedMarkerRef,
  InfoWindow,
} from '@vis.gl/react-google-maps'
import styles from '../map.module.css'
import SVGPin from '@/components/SVGIcons/SVGPin'
import DOMPurify from 'isomorphic-dompurify'
import ZbLink from '@/components/ZbLink'
import { Flex } from '@radix-ui/themes'
import { useResources } from '@/context/ResourcesContext'
import clsx from 'clsx'
import { useCallback } from 'react'
import { wrapTables } from '@/hooks/useSanitizedHtml'

export default function PinMarker(props) {
  const { isHomeMap, locationData, ...rest } = props
  const parsedData = {
    siteCode: locationData?.sitecode || locationData?.field_sitecode,
    title: locationData.field_title || locationData.title,
    position: {
      lat: parseFloat(locationData.field_latitude || locationData.latitude),
      lng: parseFloat(locationData.field_longitude || locationData.longitude),
    },
    isHighlighted: locationData.field_highlighted || locationData.highlighted,
    content: !isHomeMap
      ? locationData.field_lead || locationData.content
      : locationData?.field_popup_content,
    cta: locationData.field_cta || locationData.cta,
  }
  const [markerRef, marker] = useAdvancedMarkerRef()
  const resources = useResources()
  const containsLink = /<a\s+/.test(parsedData.content)
  const handleMouseEnter = () => rest.onMouseEnter(parsedData.siteCode)
  const handleMouseLeave = () => rest.onMouseLeave(null)
  const handleClose = useCallback(() => {
    rest.onClose()
  }, [rest])
  const handleClick = () => {
    if (marker) {
      rest.onMarkerClick(marker)
    }
  }

  return (
    <>
      <AdvancedMarker
        zIndex={rest.hoverId === parsedData.siteCode ? 1 : 0}
        position={parsedData.position}
        ref={markerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        className={styles.advancedMarker}
      >
        <div
          className={clsx(styles.pin, {
            [styles.pinHighlight]:
              rest.selectedMarker === marker ||
              rest.selectedId === parsedData.siteCode,
            [styles.pinHover]: rest.hoverId === parsedData.siteCode,
          })}
        >
          <SVGPin hasHighlight size={{ width: '44px', height: '44px' }} />
        </div>
        <div
          className={clsx(styles.pinTooltip, {
            [styles.hidePinTooltip]: rest.hoverId !== parsedData.siteCode,
          })}
        >
          <p className={styles.pinTitle}>{parsedData.title}</p>
        </div>
      </AdvancedMarker>
      {rest.selectedMarker === marker && (
        <InfoWindow
          anchor={marker}
          className={styles.infoPopup}
          onClose={handleClose}
        >
          <h4 className={styles.infoPopupTitle}>
            {'Zebrabox ' + parsedData.title}
          </h4>
          <p
            className={styles.infoPopupContent}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(wrapTables(parsedData.content || '')),
            }}
          />
          <Flex direction="row" gap="15px" align="center" justify="between">
            {!containsLink ? (
              <ZbLink
                type="base"
                className={styles.infoPopupLink}
                to={parsedData.cta.url || '#'}
              >
                {parsedData.cta.title}
              </ZbLink>
            ) : null}
            <ZbLink
              className={styles.infoPopupBookNow}
              type="button"
              to={`${resources.volumecalc_url}#/step1?location=${locationData?.field_sitecode || locationData?.sitecode}`}
            >
              <span>{resources.volumecalc_rentABox}</span>
            </ZbLink>
          </Flex>
        </InfoWindow>
      )}
    </>
  )
}
