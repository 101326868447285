'use client'

import Slider from 'react-slick'
import styles from './zbslider.module.css'
import CircleArrowButton from '@/components/buttons/CircleArrowButton'
import clsx from 'clsx'
import { ReactNode } from 'react'

function PrevArrow(props: any) {
  const { onClick } = props
  return (
    <CircleArrowButton
      className={styles.sliderButtonPrev}
      direction="left"
      onClick={onClick}
    />
  )
}

function NextArrow(props: any) {
  const { onClick } = props
  return (
    <CircleArrowButton
      className={styles.sliderButtonNext}
      direction="right"
      onClick={onClick}
    />
  )
}

export default function ZbSlider({
  centerModeDesktop = false,
  centerModeMobile = false,
  centerPaddingDesktop = '25px',
  centerPaddingMobile = '0px',
  children,
  className = '',
  dotsDesktop = false,
  dotsMobile = true,
}: {
  centerModeDesktop?: boolean
  centerModeMobile?: boolean
  dotsMobile?: boolean
  dotsDesktop?: boolean
  className?: string
  children: ReactNode
  centerPaddingDesktop?: string
  centerPaddingMobile?: string
}) {
  let childrenLength = Array.isArray(children) ? children.length : 1

  const settings = {
    adaptiveHeight: true,
    centerModeMobile: centerModeDesktop,
    centerPadding: centerPaddingDesktop,
    className,
    dots: dotsDesktop,
    infinite: true,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: childrenLength > 3 ? 3 : childrenLength,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerMode: centerModeMobile,
          centerPadding: centerPaddingMobile,
          dots: dotsMobile,
          draggable: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerMode: centerModeMobile,
          centerPadding: centerPaddingMobile,
          dots: dotsMobile,
          draggable: true,
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <Slider className={clsx(styles.sliderContainer, className)} {...settings}>
      {children}
    </Slider>
  )
}
