'use client'

import { JSX, useEffect, useState } from 'react'
import { useSanitizedHtml } from '@/hooks/useSanitizedHtml'
import 'react-perfect-scrollbar/dist/css/styles.css'
import CustomScrollbar from '@/components/HtmlWithOptionalScrollbar/CustomScrollbar'

export function HtmlWithOptionalScrollbar({
  html,
  className,
}: {
  html: string
  className?: string
}) {
  const sanitizedHtml = useSanitizedHtml(html)
  const [content, setContent] = useState<JSX.Element | null>(null)

  useEffect(() => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(sanitizedHtml, 'text/html')

    const elements = Array.from(doc.body.children).map((element, index) => {
      if (element.classList.contains('custom-scrollbar')) {
        return (
          <CustomScrollbar key={index} className="custom-scrollbar">
            <div dangerouslySetInnerHTML={{ __html: element.innerHTML }} />
          </CustomScrollbar>
        )
      }

      return (
        <div
          key={index}
          dangerouslySetInnerHTML={{ __html: element.outerHTML }}
        />
      )
    })

    setContent(<>{elements}</>)
  }, [sanitizedHtml])

  return <div className={className}>{content}</div>
}
